/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import { APIListResponse, compositeData, getData } from 'APIHandler';
import { TableFilter } from 'components/Table/TableHeaderActions/TableFilters/TableFilters';
import { CompositeAPIRequestType, CompositeAPIReturnType, LastKey, PaginationQueryParams } from 'types';
import { BasicAdminColumnFieldType } from 'components/Table/tableColumnFields';
import { AdminReportsUsersDataRow } from 'types/users';
import { AdminReportsPopularDomainsDataRow, WeeklyUserLoginsReportDataRow, AdminReportsTermsDataRow } from 'types/reports';
import { TileType, TileTypes } from 'types/tile';
import { UserCoreType } from 'types/users';
import { generateRequestURL } from 'helpers/Utils';
import { formatDateNumbersOnly } from 'helpers/Utils';
import { formatTileThumbnails } from './TileAPI';
import { SuperAdminOrganisationsCSVCustomColumns, SuperAdminOrganisationsCSVCustomColumnsById } from './OrganisationAPI';

dayjs.extend(dayjsUTC);

export type SortOptions<T extends string> = `${T}.${'asc' | 'desc'}`;

// *** USERS ***

export interface AdminUserReportData {
  items: AdminReportsUsersDataRow[];
  itemCount: number;
}

interface AdminReportsUserQueryParams extends PaginationQueryParams {
  userName?: string;
  startDate?: string;
  endDate?: string;
  eventType?: string;
  eventTargetID?: string;
  department?: string;
  gender?: string;
  geo?: string;
  location?: string;
  role?: string;
  format?: 'csv';
  sortBy?:
    | 'createdAt.asc'
    | 'createdAt.desc'
    | 'lastSeenAt.asc'
    | 'lastSeenAt.desc'
    | 'firstName.asc'
    | 'firstName.desc'
    | 'lastName.asc'
    | 'lastName.desc'
    | 'fullName.asc'
    | 'fullName.desc'
    | 'updatedAt.asc'
    | 'updatedAt.desc'
    | 'email.asc'
    | 'email.desc'
    | 'employeeID.asc'
    | 'employeeID.desc'
    | 'startedAt.asc'
    | 'startedAt.desc'
    | 'eventCount.asc'
    | 'eventCount.desc';
}

export const getAdminReportsUsersCompositeObj = (params: AdminReportsUserQueryParams, refID?: string): CompositeAPIRequestType => {
  return {
    method: 'GET',
    // Currently, the eventType for user reports will always be for logins (6)
    url: generateRequestURL('/users/*/user/reporting/users', { ...params, eventType: '6' }),
    referenceId: refID || 'getAdminReportsUsers',
  };
};

export const getAdminReportsUsers = (params: AdminReportsUserQueryParams): Promise<AdminUserReportData> => {
  // Currently, the eventType for user reports will always be for logins (6)
  return getData('NEW_UsersAPI', generateRequestURL('reporting/users', { ...params, eventType: '6' }));
};

export const getAdminReportsMonthlyUsers = async (endDate?: Date): Promise<AdminUserReportData> => {
  if (!endDate) endDate = new Date();

  try {
    const res = await getAdminReportsUsers({
      startDate: dayjs(endDate).subtract(30, 'days').format('YYYY-MM-DD'),
      endDate: dayjs(endDate).add(1, 'days').format('YYYY-MM-DD'),
      sortBy: 'eventCount.desc',
      limit: 0,
    });

    return res;
  } catch (error) {
    console.error('Error fetching monthly active users');
    return {
      items: [],
      itemCount: 0,
    };
  }
};

export const getAdminReportsWeeklyUsers = async (startDate: Date): Promise<WeeklyUserLoginsReportDataRow[]> => {
  const days = [...Array(7)].map((_, index) => dayjs(startDate).add(index, 'days').toDate());

  const requests: CompositeAPIRequestType[] = days.map((date) => {
    const start = dayjs(date).utc().format('YYYY-MM-DD');
    const end = dayjs(date).add(1, 'days').utc().format('YYYY-MM-DD'); // BE to date is exclusive so add a day

    return {
      method: 'GET',
      url: generateRequestURL('/users/*/user/reporting/users', { startDate: start, endDate: end, eventType: '6' }),
      referenceId: dayjs(date).format('dddd'),
    };
  });

  return compositeData('NEW_CompositeUsersAPI', requests)
    .then((res) =>
      res.responses.map(
        (response: CompositeAPIReturnType) =>
          ({
            day: response.referenceID,
            value: (response.body as AdminUserReportData)?.itemCount,
          } as WeeklyUserLoginsReportDataRow),
      ),
    )
    .catch(() => {
      console.error('Error fetching weekly active users');
      return [];
    });
};

export const getAdminReportsMonthlyNewUsersCompositeObj = (refID?: string): CompositeAPIRequestType => {
  return {
    method: 'GET',
    url: generateRequestURL('/users/*/user', {
      detail: 'core',
      fromCreatedAt: dayjs().subtract(30, 'days').toISOString(),
      limit: 0,
    }),
    referenceId: refID || 'getAdminReportsMonthlyNewUsers',
  };
};

// *** USER SESSIONS ***

interface AdminReportsUserSessionsQueryParams extends PaginationQueryParams {
  startDate?: string;
  endDate?: string;
  format?: 'csv';
}

export const getAdminReportsUsersSessions = (uuid: string, params: AdminReportsUserSessionsQueryParams) => {
  return getData('NEW_UsersAPI', generateRequestURL(`reporting/${uuid}/sessions`, params));
};

// CSV Types and helper functions

export type CSVCustomColumns<ColumnValues> = {
  heading: string;
  value: ColumnValues;
};

export type CSVCustomColumnsById<ColumnValues> = {
  id: string;
  value: ColumnValues;
};

export type CSVCustomColumnType =
  | PopularTilesCSVCustomColumns
  | TileInteractionsCSVCustomColumns
  | SuperAdminOrganisationsCSVCustomColumns
  | ScormCSVCustomColumns
  | CompletionCSVCustomColumns
  | MandatoryCSVCustomColumns
  | ArticleReadsCSVCustomColumns;
export type CSVCustomColumnTypeById =
  | PopularTilesCSVCustomColumnsById
  | TileInteractionsCSVCustomColumnsById
  | SuperAdminOrganisationsCSVCustomColumnsById
  | ScormCSVCustomColumnsById
  | CompletionCSVCustomColumnsById
  | MandatoryCSVCustomColumnsById
  | ArticleReadsCSVCustomColumnsById;

// BE expects Heading,value pairs - 'Tile Name,tile.name,Custom Heading,tile.type'
const convertCSVColumnsToString = (columns: CSVCustomColumnType[]): string => {
  return columns.map((column) => `${column.heading},${column.value}`).join(',');
};

export const getCsvParams = (columns: CSVCustomColumnType[], csvFileName?: string) => {
  return {
    columns: columns ? convertCSVColumnsToString(columns) : undefined,
    csvFileName: csvFileName ? csvFileName + `_${formatDateNumbersOnly(new Date())}` : undefined,
  };
};

export const getCSVColumnsData = <ColumnType extends CSVCustomColumnType, ColumnIdType extends CSVCustomColumnTypeById>(
  columnFields: BasicAdminColumnFieldType[],
  csvData: ColumnIdType[],
  splitData?: { [key: string]: ColumnType[] },
  // splitData property is an override property which allows you to insert data where an id would usually go
  // For example, if the id is createdBy, then insert 2 columns for createdByName and createdByEmail
): ColumnType[] => {
  const columnsToDownload: ColumnType[] = [];

  columnFields.forEach((column) => {
    if (splitData && column.id in splitData) {
      splitData[column.id].forEach((dataColumn) => columnsToDownload.push(dataColumn));
    } else {
      const dataItem = csvData.find((item) => column.id === item.id);
      if (dataItem) {
        columnsToDownload.push({
          heading: column.label,
          value: dataItem.value,
        } as ColumnType);
      }
    }
  });

  return columnsToDownload;
};

// *** POPULAR TILES ***

export type PopularTilesCSVCustomColumnValues =
  | 'tile.id'
  | 'tile.name'
  | 'tile.description'
  | 'tile.createdAt'
  | 'tile.updatedAt'
  | 'tile.type'
  | 'tile.attributes.url'
  | 'tile.attributes.bookmark.url'
  | 'tile.attributes.video.url'
  | 'tile.attributes.upload.filetype'
  | 'tile.attributes.upload.filename'
  | 'tile.attributes.upload.filename.type'
  | 'tile.attributes.upload.fileSizeInBytes'
  | 'tile.attributes.upload.formattedFilesize'
  | 'tile.childCount'
  | 'tile.isEnabled'
  | 'tile.isManagedByOrganisation'
  | 'tile.isMandatory'
  | 'tile.externalShareURL'
  | 'tile.sharedExternally'
  | 'tile.createdBy.id'
  | 'tile.createdBy.fullName'
  | 'tile.createdBy.department'
  | 'tile.createdBy.email'
  | 'tile.createdBy.location'
  | 'tile.reactionSummary.totalCount'
  | 'eventSummary.clickCount'
  | 'eventSummary.createCount'
  | 'eventSummary.deleteCount'
  | 'eventSummary.downloadCount'
  | 'eventSummary.externalClickCount'
  | 'eventSummary.externalDownloadCount'
  | 'eventSummary.externalReadCount'
  | 'eventSummary.readCount'
  | 'eventSummary.totalClickCount'
  | 'eventSummary.updateCount'
  | 'completionSummary.totalCount';

export type PopularTilesCSVCustomColumns = CSVCustomColumns<PopularTilesCSVCustomColumnValues>;
export type PopularTilesCSVCustomColumnsById = CSVCustomColumnsById<PopularTilesCSVCustomColumnValues>;

type ArticleReadColumns = 'user.fullName' | 'completion.createdAt';
export type ArticleReadsCSVCustomColumns = CSVCustomColumns<ArticleReadColumns>;
export type ArticleReadsCSVCustomColumnsById = CSVCustomColumnsById<ArticleReadColumns>;

export const getPopularTilesCSVColumns = (
  columnFields: BasicAdminColumnFieldType[],
  csvData: PopularTilesCSVCustomColumnsById[],
): PopularTilesCSVCustomColumns[] => {
  const splitData: { [key: string]: PopularTilesCSVCustomColumns[] } = {
    createdBy: [
      {
        heading: 'Created By Name',
        value: 'tile.createdBy.fullName',
      },
      {
        heading: 'Created By Email',
        value: 'tile.createdBy.email',
      },
    ],
  };
  return getCSVColumnsData<PopularTilesCSVCustomColumns, PopularTilesCSVCustomColumnsById>(columnFields, csvData, splitData);
};

export interface EventSummary {
  createCount: number;
  readCount: number;
  updateCount: number;
  deleteCount: number;
  clickCount: number;
  downloadCount: number;
  externalClickCount: number;
  externalReadCount: number;
  externalDownloadCount: number;
  totalClickCount: number;
}

export interface AdminReportsPopularTilesDataRow {
  eventSummary: EventSummary;
  tile: TileType;
  user: UserCoreType;
  completionSummary: {
    totalCount: number;
  };
}

export type AdminPopularTilesReportData = APIListResponse<AdminReportsPopularTilesDataRow>;

export type AdminReportsPopularTilesQueryParamsSort =
  | 'fileName.asc'
  | 'fileName.desc'
  | 'internalClicks.asc'
  | 'internalClicks.desc'
  | 'externalClicks.asc'
  | 'externalClicks.desc'
  | 'fileSize.asc'
  | 'fileSize.desc'
  | 'totalDownloads.asc'
  | 'totalDownloads.desc'
  | 'externalReads.asc'
  | 'externalReads.desc'
  | 'externalDownloads.asc'
  | 'externalDownloads.desc'
  | 'totalEvents.asc'
  | 'totalEvents.desc';

export interface AdminReportsPopularTilesQueryParams extends PaginationQueryParams {
  sortBy?: AdminReportsPopularTilesQueryParamsSort;
  from?: string;
  to?: string;
  createdBy?: string;
  fileType?: string;
  tileType?: string;
  tileName?: string;
  isManagedByOrganisation?: string;
  parentObjectID?: string;
  primarySource?: 'hub' | 'learn';
  eventType?: string;
  'tile.createdAt.from'?: string;
  'tile.createdAt.to'?: string;
  // CSV Settings
  format?: 'csv';
  csvFileName?: string;
  columns?: string;
}

export const getAdminReportsPopularTiles = async (
  params: AdminReportsPopularTilesQueryParams,
  formatThumbnails?: boolean,
): Promise<AdminPopularTilesReportData> => {
  const data = await getData('TilesAPI', generateRequestURL('reporting/populartiles', params));
  if (formatThumbnails) {
    const formattedData: AdminPopularTilesReportData = {
      ...data,
      items: data.items.map((item: AdminReportsPopularTilesDataRow) => ({
        ...item,
        tile: formatThumbnails ? formatTileThumbnails(item.tile) : item.tile,
      })),
    };
    return formattedData;
  } else {
    return data;
  }
};

interface MonthlyPopularTilesFilters {
  tileType?: string;
  endDate?: Date;
}
export const getAdminReportsMonthlyPopularTiles = async ({ tileType, endDate = new Date() }: MonthlyPopularTilesFilters = {}): Promise<
  AdminReportsPopularTilesDataRow[]
> => {
  try {
    const res = await getAdminReportsPopularTiles({
      limit: 8,
      from: dayjs(endDate).subtract(30, 'days').format('YYYY-MM-DD'),
      to: dayjs(endDate).add(1, 'days').format('YYYY-MM-DD'),
      sortBy: 'totalEvents.desc',
      isManagedByOrganisation: 'true',
      eventType: '5,7',
      ...(tileType ? { tileType: tileType as TileTypes } : {}),
    });

    return res.items;
  } catch (error) {
    console.error('Error fetching monthly popular tiles');
    return [];
  }
};

export const getAdminReportsMonthlyPopularArticles = async (): Promise<number> => {
  try {
    const res = await getAdminReportsPopularTiles({
      from: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
      to: dayjs().add(1, 'days').format('YYYY-MM-DD'),
      tileType: 'ARTICLE',
      limit: 0,
      eventType: '5',
    });

    return res.itemCount;
  } catch (error) {
    console.error('Error fetching monthly popular articles');
    return 0;
  }
};

export const popularTilesCustomCSVFieldsData: PopularTilesCSVCustomColumnsById[] = [
  {
    id: 'name',
    value: 'tile.name',
  },
  {
    id: 'tileType',
    value: 'tile.type',
  },
  // createdBy handled directly in the getPopularTilesCSVColumns function because of split
  {
    id: 'createdOn',
    value: 'tile.createdAt',
  },
  {
    id: 'domain',
    value: 'tile.attributes.url',
  },
  {
    id: 'filename',
    value: 'tile.attributes.upload.filename',
  },
  {
    id: 'totalClicks',
    value: 'eventSummary.totalClickCount',
  },
  {
    id: 'likeCount',
    value: 'tile.reactionSummary.totalCount',
  },
];

export const popularFilesCustomCSVFieldsData: PopularTilesCSVCustomColumnsById[] = [
  {
    id: 'name',
    value: 'tile.name',
  },
  // createdBy handled directly in the getPopularTilesCSVColumns function because of split
  {
    id: 'fileName',
    value: 'tile.attributes.upload.filename',
  },
  {
    id: 'fileType',
    value: 'tile.attributes.upload.filename.type',
  },
  {
    id: 'totalViews',
    value: 'eventSummary.clickCount',
  },
  {
    id: 'totalDownloads',
    value: 'eventSummary.downloadCount',
  },
  {
    id: 'fileSize',
    value: 'tile.attributes.upload.formattedFilesize',
  },
];

export const collectionsCustomCSVFieldsData: PopularTilesCSVCustomColumnsById[] = [
  {
    id: 'name',
    value: 'tile.name',
  },
  {
    id: 'type',
    value: 'tile.isManagedByOrganisation',
  },
  {
    id: 'isExternal',
    value: 'tile.sharedExternally',
  },
  // createdBy handled directly in the getPopularTilesCSVColumns function because of split
  {
    id: 'createdOn',
    value: 'tile.createdAt',
  },
  {
    id: 'internalClicks',
    value: 'eventSummary.clickCount',
  },
  {
    id: 'externalClicks',
    value: 'eventSummary.externalReadCount',
  },
];

export const collectionsTileSummaryCustomCSVFieldsData: PopularTilesCSVCustomColumnsById[] = [
  {
    id: 'tileName',
    value: 'tile.name',
  },
  // createdBy handled directly in the getPopularTilesCSVColumns function because of split
  {
    id: 'createdOn',
    value: 'tile.createdAt',
  },
  {
    id: 'totalClicks',
    value: 'eventSummary.totalClickCount',
  },
  {
    id: 'internalClicks',
    value: 'eventSummary.clickCount',
  },
  {
    id: 'externalClicks',
    value: 'eventSummary.externalClickCount',
  },
];

// *** TILE EVENTS ***

interface TileEventsReportQueryParams extends PaginationQueryParams {
  userName?: string;
  startDate?: string;
  endDate?: string;
  eventType?: string;
  eventTargetID?: string;
  eventParentID?: string;
  department?: string;
  gender?: string;
  geo?: string;
  location?: string;
  band?: string;
  role?: string;
  format?: string;
  sortBy?: string;
}

export const getTileEventsReport = (params?: TileEventsReportQueryParams) => {
  return getData('TilesAPI', generateRequestURL('reporting/events', params));
};

// *** TILE INTERACTIONS ***

export interface AdminReportsTileInteraction {
  tile: TileType;
  user: UserCoreType;
  eventTime: string;
  eventType: number;
}

interface AdminReportsTileInteractionsQueryParams extends PaginationQueryParams {
  userID?: string;
  targetObjectID?: string;
  domain?: string;
  url?: string;
  firstName?: string;
  lastName?: string;
  privilegeLevel?: string;
  email?: string;
  locale?: string;
  passwordCognitoStatus?: string;
  isEnabled?: string;
  isWelcomeEmailSent?: string;
  isManager?: string;
  employeeID?: string;
  gender?: string;
  geo?: string;
  role?: string;
  location?: string;
  band?: string;
  department?: string;
  managerID?: string;
  from?: string;
  to?: string;
  deletedAt?: string;
  lastSeen?: string;
  updatedAt?: string;
  startedAt?: string;
  eventType?: string;
  tileType?: string;
  // CSV settings
  format?: 'csv';
  csvFileName?: string;
  columns?: string;
}

export const getAdminReportsTileInteractions = (uuid: string, params: AdminReportsTileInteractionsQueryParams) => {
  return getData('TilesAPI', generateRequestURL(`reporting/${uuid}/tileinteractions`, params));
};

interface AdminReportsAllTileInteractionsQueryParams extends PaginationQueryParams {
  from?: string;
  to?: string;
  eventType?: string;
  tileType?: string;
  format?: 'csv';
}

export const getAdminReportsAllTileInteractions = (params: AdminReportsAllTileInteractionsQueryParams) => {
  return getData('TilesAPI', generateRequestURL('reporting/interactions', params));
};

export const getAdminReportsMonthlyTotalTileInteractions = async (): Promise<number> => {
  try {
    const res = await getAdminReportsAllTileInteractions({
      from: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
      to: dayjs().add(1, 'days').format('YYYY-MM-DD'),
      limit: 0,
    });

    return res.itemCount;
  } catch (error) {
    console.error('Error fetching monthly total tile interactions');
    return 0;
  }
};

export type TileInteractionsCSVCustomColumnValues =
  | 'tile.id'
  | 'tile.name'
  | 'tile.description'
  | 'tile.createdAt'
  | 'tile.updatedAt'
  | 'tile.type'
  | 'tile.attributes.url'
  | 'tile.attributes.video.url'
  | 'tile.attributes.upload.filetype'
  | 'tile.attributes.upload.filename'
  | 'tile.attributes.upload.filename.type'
  | 'tile.attributes.upload.fileSizeInBytes'
  | 'tile.attributes.upload.formattedFilesize'
  | 'tile.isManagedByOrganisation'
  | 'tile.externalShareURL'
  | 'tile.sharedExternally'
  | 'event.createdBy.id'
  | 'event.createdBy.fullName'
  | 'event.createdBy.department'
  | 'event.createdBy.email'
  | 'event.type'
  | 'event.createdAt';

export type TileInteractionsCSVCustomColumns = CSVCustomColumns<TileInteractionsCSVCustomColumnValues>;
export type TileInteractionsCSVCustomColumnsById = CSVCustomColumnsById<TileInteractionsCSVCustomColumnValues>;

export const getTileInteractionsCSVColumns = (
  columnFields: BasicAdminColumnFieldType[],
  csvData: TileInteractionsCSVCustomColumnsById[],
): TileInteractionsCSVCustomColumns[] => {
  const splitData: { [key: string]: TileInteractionsCSVCustomColumns[] } = {
    clickedBy: [
      {
        heading: 'Clicked By Name',
        value: 'event.createdBy.fullName',
      },
      {
        heading: 'Clicked By Email',
        value: 'event.createdBy.email',
      },
    ],
  };
  return getCSVColumnsData<TileInteractionsCSVCustomColumns, TileInteractionsCSVCustomColumnsById>(columnFields, csvData, splitData);
};

export const tileInteractionCustomCSVFieldsData: TileInteractionsCSVCustomColumnsById[] = [
  {
    id: 'tileType',
    value: 'tile.type',
  },
  {
    id: 'tileName',
    value: 'tile.name',
  },
  // clickedBy handled directly in the getTileInteractionsCSVColumns function because of split
  {
    id: 'clickDate',
    value: 'event.createdAt',
  },
  {
    id: 'tileLink',
    value: 'tile.attributes.url',
  },
  {
    id: 'fileName',
    value: 'tile.attributes.upload.filename',
  },
  {
    id: 'createdBy',
    value: 'tile.isManagedByOrganisation',
  },
];

// *** POPULAR DOMAINS ***

interface AdminPopularDomainsReportData {
  items: AdminReportsPopularDomainsDataRow[];
  itemCount: number;
}

interface AdminReportsPopularDomainsQueryParams extends PaginationQueryParams {
  from?: string;
  to?: string;
  domain?: string;
  format?: 'csv';
}

export const getAdminReportsPopularDomains = (params: AdminReportsPopularDomainsQueryParams): Promise<AdminPopularDomainsReportData> => {
  return getData('TilesAPI', generateRequestURL('reporting/domain', params));
};

export const getAdminReportsMonthlyPopularDomains = async (endDate?: Date, limit = 6): Promise<AdminReportsPopularDomainsDataRow[]> => {
  if (!endDate) endDate = new Date();

  try {
    const res = await getAdminReportsPopularDomains({
      limit,
      from: dayjs(endDate).subtract(30, 'days').format('YYYY-MM-DD'),
      to: dayjs(endDate).add(1, 'days').format('YYYY-MM-DD'),
    });

    return res.items;
  } catch (error) {
    console.error('Error fetching monthly popular domains');
    return [];
  }
};

// *** TERMS AND CONDITIONS ***

interface AdminReportsTermsData {
  items: AdminReportsTermsDataRow[];
  itemCount: number;
}

interface AdminReportsTermsQueryParams extends PaginationQueryParams {
  userName?: string;
  acceptedFrom?: string;
  acceptedTo?: string;
  format?: 'csv';
}

export const getAdminReportsTerms = (params: AdminReportsTermsQueryParams): Promise<AdminReportsTermsData> => {
  return getData('OrganisationAPI', generateRequestURL('reporting/termsconditions', params));
};

// *** RECOGNITION ***

const defaults = {
  perPage: 5,
  offset: 0,
};

interface SimpleTableFilter {
  id: string;
  value: string | Date;
  inputType?: 'date' | 'select';
}

interface SearchTermRecognitionReport {
  message: string;
  giver: string;
  receiver: string;
}

export const formatFiltersRecognitionReport = (filters: TableFilter[] | SimpleTableFilter[], searchTerm?: SearchTermRecognitionReport) => {
  const queryParams = new URLSearchParams();
  let hasSearch = false;
  const hasFilter = filters.filter((f) => f.value).length > 0;

  // Add search params if present
  const searchItems = searchTerm ? Object.entries(searchTerm) : [];
  searchItems.forEach((item) => {
    if (item[1].length > 0) {
      queryParams.append(item[0] === 'message' ? 'search' : item[0], item[1]);
      hasSearch = true;
    }
  });

  // // Add filter queries if present
  if (hasFilter) {
    filters
      .filter((f) => f.value && f.value !== 'all')
      .forEach((filter) => {
        let searchValue = filter.value;
        if (filter.inputType === 'date') {
          // TO DO: Send ISO String when the backend supports it
          // searchValue = dayjs(filter.value).toISOString();
          searchValue = dayjs(filter.value).format('DD/MM/YYYY');
        }

        queryParams.append(filter.id, searchValue as string);
      });
  }

  if (hasSearch || hasFilter) {
    return `${queryParams}&`;
  }

  return '';
};

interface GetRecognitionReportOptions {
  perPage?: number;
  filters?: TableFilter[] | SimpleTableFilter[];
  searchTerm?: SearchTermRecognitionReport;
  lastKey?: LastKey;
}

export const getAdminReportsRecognition = ({ perPage = defaults.perPage, searchTerm, filters = [], lastKey }: GetRecognitionReportOptions) => {
  return getData(
    'RecognitionAPI',
    `admin/reports/given/?${formatFiltersRecognitionReport(filters, searchTerm)}Limit=${perPage}${
      lastKey ? `&LastKey=${encodeURIComponent(JSON.stringify(lastKey))}` : ''
    }`,
  );
};

export const getAdminReportsMonthlyRecognition = async (endDate?: Date): Promise<number> => {
  if (!endDate) endDate = new Date();

  try {
    const res = await getAdminReportsRecognition({
      // Temporary hacky way with high perPage
      perPage: 1000,
      filters: [
        {
          inputType: 'date',
          id: 'date_from',
          value: dayjs(endDate).subtract(30, 'days').toDate(),
        },
        {
          inputType: 'date',
          id: 'date_to',
          value: endDate,
        },
      ],
    });

    return res.data.length;
  } catch (error) {
    console.error('Error fetching monthly recognition');
    return 0;
  }
};

// *** SCORM ***

export interface AdminLearnReportsScormDataRow {
  tile: TileType;
  student: UserCoreType;
  attempt: {
    number: number;
    timeSpentInSeconds: number;
    status: string;
    scorePercentage: number;
    createdAt: string;
    updatedAt: string;
  };
}

export type ScormCSVCustomColumnValues =
  | 'tile.id'
  | 'tile.name'
  | 'tile.description'
  | 'tile.createdAt'
  | 'tile.updatedAt'
  | 'tile.type'
  | 'tile.isManagedByOrganisation'
  | 'tile.attributes.upload.filename'
  | 'tile.attributes.upload.fileSizeInBytes'
  | 'tile.attributes.upload.formattedFilesize'
  | 'student.id'
  | 'student.firstName'
  | 'student.lastName'
  | 'student.fullName'
  | 'student.email'
  | 'student.department'
  | 'student.gender'
  | 'student.geo'
  | 'student.location'
  | 'student.role'
  | 'student.startDate'
  | 'attempt.number'
  | 'attempt.timeSpentInSeconds'
  | 'attempt.status'
  | 'attempt.scorePercentage'
  | 'attempt.createdAt'
  | 'attempt.updatedAt';

export type ScormCSVCustomColumns = CSVCustomColumns<ScormCSVCustomColumnValues>;
export type ScormCSVCustomColumnsById = CSVCustomColumnsById<ScormCSVCustomColumnValues>;

export const scormCustomCSVFieldsData: ScormCSVCustomColumnsById[] = [
  {
    id: 'itemName',
    value: 'tile.name',
  },
  {
    id: 'userName',
    value: 'student.fullName',
  },
  {
    id: 'userEmail',
    value: 'student.email',
  },
  {
    id: 'score',
    value: 'attempt.scorePercentage',
  },
  {
    id: 'status',
    value: 'attempt.status',
  },
  {
    id: 'date',
    value: 'attempt.createdAt',
  },
];

export const scormAdvancedCustomCSVFieldsData: ScormCSVCustomColumnsById[] = [
  {
    id: 'itemName',
    value: 'tile.name',
  },
  {
    id: 'userName',
    value: 'student.fullName',
  },
  {
    id: 'userEmail',
    value: 'student.email',
  },
  {
    id: 'attemptNumber',
    value: 'attempt.number',
  },
  {
    id: 'score',
    value: 'attempt.scorePercentage',
  },
  {
    id: 'status',
    value: 'attempt.status',
  },
  {
    id: 'date',
    value: 'attempt.createdAt',
  },
  {
    id: 'attemptTime',
    value: 'attempt.timeSpentInSeconds',
  },
];

export const getScormCSVColumns = (columns: BasicAdminColumnFieldType[], csvData: ScormCSVCustomColumnsById[]) => {
  return getCSVColumnsData<ScormCSVCustomColumns, ScormCSVCustomColumnsById>(columns, csvData);
};

interface AdminLearnReportsScormQueryParams extends PaginationQueryParams {
  mode?: 'EXHAUSTIVE' | 'CONDENSED';
  format?: 'csv';
  'tile.ids'?: string;
  'audience.ids'?: string;
  'attempt.createdAt.from'?: string;
  'attempt.createdAt.to'?: string;
  'student.id'?: string;
  'student.search'?: string;
  'tile.search'?: string;
}

export const getAdminLearnReportsScorm = (params: AdminLearnReportsScormQueryParams) => {
  return getData('ScormAPI', generateRequestURL('reporting/interactions', params));
};

// *** LEARN ITEM COMPLETION ***

export interface AdminReportsCompletionDataRow {
  tile: TileType;
  user: UserCoreType;
  completion?: {
    isPassed: boolean;
    createdAt: string;
    updatedAt: string;
  };
  progress?: {
    proportionComplete: number; // Between 0 and 1
    firstAccessedAt: string;
    lastAccessedAt: string;
  };
  enrolledAt?: string; // Mandatory items only
}

export type CompletionCSVCustomColumnValues =
  | 'tile.name'
  | 'tile.type'
  | 'user.fullName'
  | 'user.email'
  | 'completion.isPassed'
  | 'completion.isCompleted'
  | 'completion.createdAt';

export type CompletionCSVCustomColumns = CSVCustomColumns<CompletionCSVCustomColumnValues>;
export type CompletionCSVCustomColumnsById = CSVCustomColumnsById<CompletionCSVCustomColumnValues>;

export const getCompletionCSVColumns = (columns: BasicAdminColumnFieldType[]) => {
  // Ids must match up with columnFields data
  const customCSVFieldsData: CompletionCSVCustomColumnsById[] = [
    {
      id: 'itemName',
      value: 'tile.name',
    },
    {
      id: 'itemType',
      value: 'tile.type',
    },
    {
      id: 'userName',
      value: 'user.fullName',
    },
    {
      id: 'userEmail',
      value: 'user.email',
    },
    {
      id: 'completionStatus',
      value: 'completion.isPassed',
    },
    {
      id: 'completionDate',
      value: 'completion.createdAt',
    },
  ];

  const splitData: { [key: string]: CompletionCSVCustomColumns[] } = {
    completionStatus: [
      {
        heading: 'Is Completed',
        value: 'completion.isCompleted',
      },
      {
        heading: 'Is Passed',
        value: 'completion.isPassed',
      },
    ],
  };

  return getCSVColumnsData<CompletionCSVCustomColumns, CompletionCSVCustomColumnsById>(columns, customCSVFieldsData, splitData);
};

type AdminLearnReportsCompletionQueryParamsSort = SortOptions<
  | 'tile.createdAt'
  | 'tile.updatedAt'
  | 'tile.dueAt'
  | 'completion.createdAt'
  | 'completion.updatedAt'
  | 'completion.isPassed'
  | 'progress.lastAccessedAt'
  | 'progress.firstAccessedAt'
  | 'progress.proportionComplete'
  | 'user.createdAt'
  | 'user.email'
  | 'user.employeeID'
  | 'user.firstName'
  | 'user.fullName'
  | 'user.lastName'
  | 'user.startedAt'
  | 'user.updatedAt'
>;

interface AdminLearnReportsCompletionQueryParams extends PaginationQueryParams {
  sortBy?: AdminLearnReportsCompletionQueryParamsSort | AdminLearnReportsCompletionQueryParamsSort[];
  'tile.name'?: string;
  'tile.type'?: string;
  'tile.id'?: string;
  'tile.primarySource'?: 'hub' | 'learn';
  'tile.isMandatory'?: boolean;
  'user.id'?: string;
  'completion.isPassed'?: boolean;
  'completion.isCompleted'?: boolean;
  'completion.createdAt.from'?: string;
  'completion.createdAt.to'?: string;
  'progress.lastAccessedAt.from'?: string;
  'progress.lastAccessedAt.to'?: string;
  format?: 'csv';
}

// NOTE: Temporary function which can be placed at a higher level once we decide how to apply to wider app
// Formats sortBy property so that string arrays are converted to comma separated
const formatSort = (sortQuery?: string | string[]) => {
  if (!sortQuery || typeof sortQuery === 'string') return sortQuery;
  return sortQuery.join(',');
};

export const getAdminReportsCompletion = (params: AdminLearnReportsCompletionQueryParams) => {
  return getData('TilesAPI', generateRequestURL('reporting/completions', { ...params, sortBy: formatSort(params.sortBy) }));
};

export type MandatoryCSVCustomColumnValues =
  | 'tile.name'
  | 'tile.type'
  | 'user.fullName'
  | 'user.email'
  | 'enrolledAt'
  | 'progress.lastAccessedAt'
  | 'completion.status'
  | 'completion.createdAt';

export type MandatoryCSVCustomColumns = CSVCustomColumns<MandatoryCSVCustomColumnValues>;
export type MandatoryCSVCustomColumnsById = CSVCustomColumnsById<MandatoryCSVCustomColumnValues>;

export const getMandatoryCSVColumns = (columns: BasicAdminColumnFieldType[]) => {
  // Ids must match up with columnFields data
  const customCSVFieldsData: MandatoryCSVCustomColumnsById[] = [
    {
      id: 'itemName',
      value: 'tile.name',
    },
    {
      id: 'itemType',
      value: 'tile.type',
    },
    {
      id: 'userName',
      value: 'user.fullName',
    },
    {
      id: 'userEmail',
      value: 'user.email',
    },
    {
      id: 'enrolmentDate',
      value: 'enrolledAt',
    },
    {
      id: 'updatedAt',
      value: 'progress.lastAccessedAt',
    },
    {
      id: 'completionStatus',
      value: 'completion.status',
    },
    {
      id: 'completionDate',
      value: 'completion.createdAt',
    },
  ];

  return getCSVColumnsData<MandatoryCSVCustomColumns, MandatoryCSVCustomColumnsById>(columns, customCSVFieldsData);
};
